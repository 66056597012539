<template>
  <!-- 数字通证管理 -->
  <div>
    <!-- 新增按钮 -->
    <div class="onBtn">
      <el-button type="success" style="margin-left: auto" @click="New"
        >新增</el-button
      >
    </div>
    <!-- 内容区域 -->
    <div>
      <el-table :data="data" border style="width: 100%">
        <el-table-column
          prop="certificateId"
          label="数字通证名称"
          width=""
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tokenMin"
          label="提数字通证最小值"
          width=""
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tokenMax"
          label="提数字通证最大值"
          width=""
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="token_server_change"
          label="手续费比例"
          width=""
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="update_by"
          label="最后操作人"
          width=""
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" width="" align="center">
          <template slot-scope="scope">
            <div class="btn">
              <div @click="onToView(scope.row.id)">查看</div>
              <div @click="onOffline(scope.row.id)">编辑</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <!-- <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      total: null,
    };
  },
  created() {
    // 请求所有通证
    this.setPassCard();
  },
  methods: {
    // 点击新增
    New() {
      // 跳转新增页面
      this.$router.push("AddthroughConfiguration");
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },

    // 查看数字通证
    onToView(id) {
      // 跳转新页面
      this.$router.push({
        path: "checkPassCard",
        query: { id },
      });
    },
    // 编辑数字通证
    onOffline(id) {
      // 跳转新页面
      console.log(id);
      this.$router.push("configurationPassCard");
    },
    // 请求所有数字通证
    setPassCard() {
      this.axios.get("/admin/tokenRule/findAll", {}).then((res) => {
        console.log(res);
        this.data = res.data.data;
        this.total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
